.Joke {
    display: flex;
    border-bottom: 2px solid #eeeeee;
    justify-content: center;
    font-weight: 400;
    padding: 1rem;
}

.Joke-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    margin-right: 1rem;
}

.Joke-text {
    width: 75%;
    font-size: 1.2rem;
}

.Joke-votes {
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    border: 3px solid red;
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    box-shadow: 0 10px 38px rgba(0, 0, 0, 0.2), 0 10px 12px rgba(0, 0, 0, 0.1)
}

.fa-arrow-up,
.fa-arrow-down {
    font-size: 1.5em;
    margin: 10px;
    cursor: pointer;
    transition: all 0.3s;
}

.fa-arrow-up:hover {
    animation-name: move-up;
    animation-duration: 0.7s;
    animation-timing-function: ease-out;
    color: #8bc34a;
}

.fa-arrow-down:hover {
    animation-name: move-down;
    animation-duration: 0.7s;
    animation-timing-function: ease-out;
    color: #f44336;
}

.Joke-smiley {
    font-size: 3rem;
    margin-left: auto;
    border-radius: 50%;
    box-shadow: 0 10px 38px rgba(0, 0, 0, 0.2), 0 10px 12px rgba(0, 0, 0, 0.1)
}

.Joke-smiley:hover {
    animation-name: spin;
    animation-duration: 0.7s;
    animation-timing-function: ease-in-out;
    animation-delay: 0;

}

@keyframes move-up {
    0%, 50%, 100% {
        transform: translateY(0);
    }
    25%, 75% {
        transform: translateY(-3px);
    }
}

@keyframes move-down {
    0%, 50%, 100% {
        transform: translateY(0);
    }
    25%, 75% {
        transform: translateY(3px);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    30% {
        transform: rotate(20deg);
    }
    60% {
        transform: rotate(0deg);
    }
    80% {
        transform: rotate(10deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@media screen and (max-width: 1300px) {

    .Joke-text {
        /* width: 75%;
        font-size: 1rem;
        margin: 0 20px; */
        align-self: center;
    }

    .Joke-buttons {
        width: 21%;
    }

    .Joke-smiley {
        /* width: 10%;
        height: 5%; */
    }
    
  }

@media screen and (max-width: 750px) {

    .Joke-text {
        width: 75%;
        font-size: 1rem;
        margin: 0 20px;
        align-self: center;
    }

    .Joke-buttons {
        width: 30%;
    }

    .Joke-smiley {
        width: 10%;
        height: 5%;
    }
    
  }

@media screen and (max-width: 450px) {

    .Joke-text {
        width: 75%;
        font-size: 1rem;
        margin: 0 20px 0 -5px;
        align-self: center;
    }

    .Joke-buttons {
        width: 40%;
    }

    .Joke-smiley {
        width: 30%;
        height: 30%;
        align-self: center;
    }
    
  }

  @media screen and (max-width: 350px) {

    .Joke-text {
        font-size: 15px;
        margin: 0 5px 0 -10px;
        align-self: center;
    }

    .Joke-buttons {
        width: 50%;
    }

    .Joke-smiley {
        width: 30%;
        height: 30%;
        font-size: 2.5rem;
        align-self: center;
    }

    .fa-arrow-up, .fa-arrow-down {
        font-size: 1.2em;
        margin: 0 5px;
        cursor: pointer;
        transition: all 0.3s;
    }

    .Joke-votes {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }
    
  }

.JokeList {
    display: flex;
    width: 80%;
    height: 80%;
}

.JokeList-sidebar {
    background: #9575cd;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    text-align: center;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.1), inset 0 0 25px #7e57c2;
    z-index: 2;
}

.JokeList-title {
    font-size: 4rem;
    margin: 2rem;
    color: white;
    font-weight: 300;
    letter-spacing: 0.6rem;
}

.JokeList-title span {
    font-weight: 700;
    letter-spacing: 0;
}

.JokeList-sidebar img {
    width: 50%;
    border-radius: 50%;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.1)
}

.JokeList-sidebar img:hover {
    animation-name: shake-slow;
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
}

.JokeList-jokes {
    height: 90%;
    background: white;
    align-self: center;
    width: 70%;
    overflow: scroll;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.1)
}

.JokeList-spinner {
    color: white;
    text-align: center;
}

.JokeList-getmore {
    font-size: 1.5rem;
    width: 50%;
    border-radius: 2rem;
    padding: 1rem 2rem;
    margin: 2rem;
    color: white;
    word-spacing: 0.5rem;
    font-weight: 700;
    border: none;
    cursor: pointer;
    transition: 0.8s cubic-bezier(0.2, 1, 0.2, 1);
    outline: none;
    background: linear-gradient(
        135deg,
        rgba(179, 229, 252, 1) 0%,
        rgba(179, 229, 252, 1) 50%,
        rgba(240, 98, 146, 1) 50%,
        rgba(240, 98, 146, 1) 100%
    );
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.1)
}

.JokeList-getmore:hover {
    transform: translateY(-2px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25);
}

@keyframes shake-slow {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    2% {
        transform: translate(5px, -6px) rotate(3.5deg);
    }
    4% {
        transform: translate(1px, -4px) rotate(2.5deg);
    }
    6% {
        transform: translate(-4px, -6px) rotate(0.5deg);
    }
    8% {
        transform: translate(2px, -9px) rotate(-0.5deg);
    }
    10% {
        transform: translate(5px, -5px) rotate(0.5deg);
    }
    12% {
        transform: translate(6px, 1px) rotate(0.5deg);
    }
    14% {
        transform: translate(5px, 2px) rotate(-1.5deg);
    }
    16% {
        transform: translate(8px, -6px) rotate(-0.5deg);
    }
    18% {
        transform: translate(-6px, -6px) rotate(3.5deg);
    }
    20% {
        transform: translate(-8px, 7px) rotate(-1.5deg);
    }
    22% {
        transform: translate(3px, 9px) rotate(1.5deg);
    }
    24% {
        transform: translate(-7px, 6px) rotate(3.5deg);
    }
    26% {
        transform: translate(9px, -5px) rotate(1.5deg);
    }
    28% {
        transform: translate(10px, 1px) rotate(-1.5deg);
    }
    30% {
        transform: translate(-8px, 5px) rotate(3.5deg);
    }
    32% {
        transform: translate(-9px, 5px) rotate(-1.5deg);
    }
    34% {
        transform: translate(-6px, 8px) rotate(0.5deg);
    }
    36% {
        transform: translate(6px, -2px) rotate(0.5deg);
    }
    38% {
        transform: translate(3px, 3px) rotate(3.5deg);
    }
    40% {
        transform: translate(6px, 10px) rotate(-1.5deg);
    }
    42% {
        transform: translate(2px, -2px) rotate(1.5deg);
    }
    44% {
        transform: translate(-6px, 8px) rotate(-0.5deg);
    }
    46% {
        transform: translate(-5px, 9px) rotate(-2.5deg);
    }
    48% {
        transform: translate(-4px, 2px) rotate(3.5deg);
    }
    50% {
        transform: translate(-9px, -3px) rotate(-1.5deg);
    }
    52% {
        transform: translate(3px, -2px) rotate(1.5deg);
    }
    54% {
        transform: translate(10px, 8px) rotate(3.5deg);
    }
    56% {
        transform: translate(7px, 8px) rotate(0.5deg);
    }
    58% {
        transform: translate(-4px, -3px) rotate(-0.5deg);
    }
    60% {
        transform: translate(9px, -4px) rotate(-1.5deg);
    }
    62% {
        transform: translate(-8px, -6px) rotate(1.5deg);
    }
    64% {
        transform: translate(-1px, -2px) rotate(3.5deg);
    }
    66% {
        transform: translate(0px, -8px) rotate(0.5deg);
    }
    68% {
        transform: translate(8px, -7px) rotate(0.5deg);
    }
    70% {
        transform: translate(3px, 4px) rotate(1.5deg);
    }
    72% {
        transform: translate(3px, 2px) rotate(1.5deg);
    }
    74% {
        transform: translate(-5px, -9px) rotate(0.5deg);
    }
    76% {
        transform: translate(1px, -8px) rotate(2.5deg);
    }
    78% {
        transform: translate(3px, 7px) rotate(-1.5deg);
    }
    80% {
        transform: translate(-9px, -4px) rotate(0.5deg);
    }
    82% {
        transform: translate(0px, -4px) rotate(-2.5deg);
    }
    84% {
        transform: translate(-7px, -3px) rotate(0.5deg);
    }
    86% {
        transform: translate(-8px, 6px) rotate(-1.5deg);
    }
    88% {
        transform: translate(3px, 4px) rotate(2.5deg);
    }
    90% {
        transform: translate(5px, 7px) rotate(2.5deg);
    }
    92% {
        transform: translate(2px, -8px) rotate(-2.5deg);
    }
    94% {
        transform: translate(-1px, -2px) rotate(-1.5deg);
    }
    96% {
        transform: translate(-4px, 10px) rotate(-1.5deg);
    }
    98% {
        transform: translate(10px, -9px) rotate(2.5deg);
    }
}

@media screen and (max-width: 1300px) {

    .JokeList {
        width: 100%;
        height: 90%;
        /* flex-direction: column; */
    }

    .JokeList-getmore {
        width: 60%;
    }
  }

@media screen and (max-width: 750px) {

    .JokeList {
        width: 100%;
        height: 100%;
        flex-direction: column;
    }

    .JokeList-sidebar {
        margin: 0 auto;
        width: 100%;
    }

    .JokeList-jokes {
        width: 100%;
    }

    .JokeList-img {
        width: 30%;
    }
  }

@media screen and (max-width: 450px) {

    .JokeList {
        width: 100%;
        height: 100%;
        flex-direction: column;
    }

    .JokeList-sidebar {
        margin: 0 auto;
        width: 100%;
    }

    .JokeList-jokes {
        width: 100%;
    }

    .JokeList-title {
        font-size: 3rem;
        margin: 0rem;
    }

    .JokeList-img {
        width: 30%;
    }
  }

/* @media screen and (min-width: 350px) {

    .JokeList {
        width: 100%;
        height: 100%;
        flex-direction: column;
    }

    .JokeList-sidebar {
        margin: 0 auto;
        width: 100%;
    }

    .JokeList-jokes {
        width: 100%;
    }

    .JokeList-title {
        font-size: 3rem;
        margin: 0rem;
    }

    .JokeList-getmore {
        font-size: 1.5rem;
        width: 60%;
        border-radius: 2rem;
        padding: 0;
        margin: 1rem;
    }


    .JokeList-sidebar {
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.05), 0 15px 12px rgba(0, 0, 0, 0.1), inset 0 0 25px #7e57c2;
        z-index: 2;
    }
  } */

@media screen and (max-width: 350px) {

    .JokeList {
        width: 100%;
        height: 100%;
        flex-direction: column;
    }

    .JokeList-sidebar {
        margin: 0 auto;
        width: 100%;
    }

    .JokeList-jokes {
        width: 100%;
    }

    .JokeList-title {
        font-size: 3rem;
        margin: 0rem;
    }

    .JokeList-getmore {
        font-size: 1.5rem;
        width: 60%;
        border-radius: 2rem;
        margin: 1rem;
        padding: 1rem 1rem;
    }


    .JokeList-sidebar {
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.05), 0 15px 12px rgba(0, 0, 0, 0.1), inset 0 0 25px #7e57c2;
        z-index: 2;
    }
  }